/* eslint-disable react/react-in-jsx-scope */
import { useState, useEffect } from 'react';
import style from './signupModal.module.css'
import CheckMark from './checkMark'
import consts from './consts'
import utils from './utils'
import LoginForm from './loginForm'
import CloseIcon from './closeIcon'
import LoaderOverlay from './loaderOverlay'

import { useStateValue } from '../state';

export default function SignupModal({onClose}) {
  const [showWhySignupTooltip, updateShowWhySignupTooltip] = useState(false);
  const [emailSignupOpen, updateEmailSignupOpen] = useState(false);
  const [hideWhySignup, updateHideWhySignup] = useState(false);

  const [
    {loading},
  ] = useStateValue()

  useEffect(() => {
     utils.trackPageView('signupModal');
     utils.reportGoogleAnalyticsConversion(consts.GA_CONVERSION_TYPES.SIGNUP_VISIT);
    }, [])

  function renderFeature(featureText) {
    return (
      <div className={style.fetaureContainer}>
        <CheckMark iconStyle={{marginRight: '12px'}}/>
        <span className={style.featureText}>
          {featureText}
        </span>
      </div>
    );
  }

  function renderFeatureList() {
    const features = ['Blazing Fast file transfers', 'Up to 50GB per transfer', '30 days file retention', 'Branded download page', 'Instant sharing without verification'];
    return (
      <div className={style.featureList}>
          {features.map(f => 
          {
            return renderFeature(f);
          })}
      </div>
    );
  }

  function renderInfoIcon() {
    return(
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M9.58797 18.3068C14.331 18.3068 18.1759 14.4618 18.1759 9.71883C18.1759 4.97582 14.331 1.13086 9.58797 1.13086C4.84496 1.13086 1 4.97582 1 9.71883C1 14.4618 4.84496 18.3068 9.58797 18.3068Z" stroke="#13BCB4" strokeWidth="1.65199" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M9.58789 5.90234H9.59843" stroke="#13BCB4" strokeWidth="1.65199" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8.63477 9.71875H9.58898V13.5356H10.5432" stroke="#13BCB4" strokeWidth="1.65199" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    );
  }

  function handleOnMouseEnter() {
    updateShowWhySignupTooltip(true);
  }

  function handleOnMouseLeave() {
    updateShowWhySignupTooltip(false);
  }

  function renderWhySignupTooltip() {
    return (
      <div className={style.whySignupTooltipContainer}>
        <div className={style.whySignupTooltip}>
          <span className={style.whySignupTooltipText}>We take security seriously. We need to verify your email to avoid abusive use of FileDriver and make sure we protect all of our users 💚</span>
          <div className={style.whySignupTooltipPointer}/>
        </div>
      </div>
    );
  }

    return (
<div className={style.overlayContainer}>
{loading && <LoaderOverlay LoaderOverlayStyle={{zIndex: '2002'}}>
      <span className={style.loaderText}>{'Creating your profile'}</span>
      <span className={style.loaderTextSubHeadline}>It might take a few moments, please don't close this page.</span>
      </LoaderOverlay>}  <div className={style.container}>
    <div className={style.leftBlock}>
    <div className={style.modalContent}>
      <span className={style.title}>Hello Stranger!</span>
      <span className={style.subTitle}>Complete your free profile and let's send some files. </span>
    <LoginForm formStyle={{height: '215px', position: 'relative', marginTop: '22px', marginRight: '28px', minWidth: '334px', boxShadow: 'unset', border: 'none'}}
    onCompletion={() => window.location.assign('/')}
    actionType='signup' buttonText={'Sign up with email'}
    acceptedTerms={true}
    verificationStyle={{paddingTop: '2px'}}
    inputStyle={{width: '243px`'}}
    signupFormStyle={{paddingTop: emailSignupOpen ? '0px' : '30px'}}
    onEmailSignupClick={() => updateEmailSignupOpen(true)}
    onSuccess={() => updateHideWhySignup(true)}
    >
    </LoginForm >
    <div className={style.footer}>
     {!hideWhySignup && <div className={style.whySignupWrapper}>
      {showWhySignupTooltip && emailSignupOpen &&
          renderWhySignupTooltip()
        }
      <div className={emailSignupOpen ? style.whySignupContainerMinimized : style.whySignupContainer}>
        <div onMouseOver={handleOnMouseEnter} onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave}  className={style.whySignupTextContainer}>
        {renderInfoIcon()}
        <span className={style.whySignup}>Why do I need to sign up?</span>
        </div>
        {!showWhySignupTooltip && <span className={emailSignupOpen ? style.whySignupTextHidden : style.whySignupText}>We take security seriously. We need to verify your email to avoid abusive use of FileDriver and make sure we protect all of our users 💚</span>}
      </div>
      </div>}
    <span className={style.alreadyHaveAccount}>Already have an account? <a href='/posts/login' style={{textDecoration: 'underline', color: '#13BCB4', cursor: 'pointer', padding: '0', fontSize: '16px', marginLeft: '6px'}}>Log in</a></span>
    </div>
      </div>
    </div>
    <div className={style.rightBlock}>
    <CloseIcon onClick={onClose} iconStyle={{
          width: "30px", 
          height: "30px",
          cursor: "pointer",
          position: 'absolute',
          top: '0px',
          right: '0px',
          marginTop: '16px',
          marginRight: '14px',
          zIndex: 10,
          opacity: 0.5,
       }} svgStyle={{position: 'relative', left: '0.5px', top: '0.5px'}}/>
      <img src="https://media1.tenor.com/images/645ad113e588870749ceae1d1ae497c8/tenor.gif?itemid=10875575" className={style.formGif}/>
      <div className={style.featuresContainer}>
      <span className={style.featuresTitle}>Sign up to Pro and get:</span>
      {renderFeatureList()}
      </div>
    </div>
   </div>
      </div>

    )
  }